import { useContext, useEffect, useState } from "react";
import { I18nContext } from "../../../contexts/i18n.context";
import HttpLeadRepository from "../../../api/request/Lead/lead.service";
import { ModalDataI } from "../EditProjects.types";
import { AvailableTraits } from "../../../api/request/Tarifier/Model/Response/CapitalLimitsResponse.model";
import { InsuranceType } from "../../../api/request/Lead/Model/Request/CarrierPricesRequest.model";
import { SimulatePremiumResponse } from "../../../api/request/Lead/Model/Response/SimulatePremiumResponse";
import { validateRegex } from "../../../utils/formValidator";
import apiLeadSendDocument from "../../../api/request/apiLeadSendDocument";
import { ContextGetlife } from "../../../contexts/ContextGetlife";

interface formErrorsI {
  phone?: string;
  email?: string;
}

export const sanitizeCapital = (value: string) => {
  return value.replace("€", "").replaceAll(".", "").trim();
};

const EditProjectsController = ({
  modalData,
  recoverProject,
  setShowModal,
  showModal,
  fetchProjects,
  token,
}: any) => {
  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;
  const {
    state: { translate },
  } = useContext(I18nContext);
  const { setShowNotification } = useContext(ContextGetlife);
  const [downloadableModal, setDownloadableModal] = useState(false);
  const leadRepository = new HttpLeadRepository(token);
  const [values, setValues] = useState<ModalDataI>({} as ModalDataI);

  const [traits, setTraits] = useState<AvailableTraits>({
    DEATH: true,
    DISABILITY: true,
  });
  const [prices, setPrices] = useState<SimulatePremiumResponse>(
    {} as SimulatePremiumResponse
  );
  const [downloadablePdfs, setDownloadablePdfs] = useState<
    [{ template: string; url: string }] | []
  >([]);
  const [isActiveRelatedEmail, setIsActiveRelatedEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<formErrorsI>({});
  const [isSaved, setIsSaved] = useState<Boolean>(false);

  const isSeriousIllnesses = values.coverage === "eg";

  const fetchUpdatePrices = async (capital: number, coverage: string) => {
    try {
      const response = await leadRepository.getSimulatePremium({
        capital,
        coverage: coverage as InsuranceType,
        leadId: modalData.leadId,
      });
      setPrices(response);
      setTraits(response.availableTraits);
    } catch (error) {
      throw error;
    }
  };

  const fetchDownloadables = async (leadId: string) => {
    const downloadablePdfs = await leadRepository.getAvailableDocuments(leadId);
    setDownloadablePdfs(downloadablePdfs as [{ template: string; url: string }]);
  };

  const updateValues = (field: keyof ModalDataI, value: any) => {
    setValues({ ...values, [field]: value });
  };

  const validateForm = () => {
    const _error: formErrorsI = {};
    if (values.phone && validateRegex("PHONE", values.phone) === true)
      _error.phone = translate("edit.projects.phone.badFormat");
    if (!values.email)
      _error.email = translate("edit.projects.email.mandatory");
    if (values.email && validateRegex("EMAIL", values.email) === true)
      _error.email = translate("edit.projects.email.badFormat");
    return _error;
  };

  const saveData = async (): Promise<boolean> => {
    const _error = validateForm();
    if (Object.keys(_error).length > 0) {
      setError(_error);
      return true;
    }
    setError({});
    setIsLoading(true);
    const {
      capital,
      coverage,
      referenceName,
      phone,
      email,
      birthDate,
      relatedEmail,
    } = values;
    try {
      await leadRepository.putLeadUpdates(values.leadId, {
        capital: parseInt(capital as unknown as string),
        coverage: coverage as InsuranceType,
        referenceName,
        phone,
        email,
        birthDate,
        relatedEmail,
      });
      setTimeout(() => fetchProjects({}), 1500);
      setIsSaved(true);
      setIsLoading(false);
      return false;
    } catch (error) {
      setIsLoading(false);
      return true;
    }
  };

  const saveDataAndRecoverProject = async () => {
    const _error = await saveData();
    if (_error) return;
    recoverProject(
      values.leadId,
      { ...values, insuranceType: values.coverage },
      true
    );
  };

  const closeModalCallback = () => {
    setError({});
    setShowModal(false);
    setIsSaved(false);
    setDownloadablePdfs([]);
  };

  const formatPriceWithoutCurrency = (price: number) => {
    return Intl.NumberFormat("es", {
      style: "currency",
      currency: "EUR",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
      .format(price)
      .replace("€", "")
      .trim();
  };

  useEffect(() => {
    if (showModal) {
      // @ts-ignore
      setPrices({} as SimulatePremiumResponse);
      setIsActiveRelatedEmail(false);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  useEffect(() => {
    (async () => {
      if (Object.keys(modalData).length > 0) {
        setValues(modalData);
        await fetchUpdatePrices(modalData.capital, modalData.coverage);
        await fetchDownloadables(modalData.leadId);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData]);

  useEffect(() => {
    (async () => {
      if (values.coverage) {
        await fetchUpdatePrices(values.capital, values.coverage);
        await fetchDownloadables(modalData.leadId);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.coverage]);

  const sendEmailDocument = async (email: string, text: string, template: string) => {
    const type = "general-a-exclusions";
    const resource = null;
    try {
      await apiLeadSendDocument(
        token,
        modalData.leadId,
        type,
        text,
        email,
        resource,
        template.includes(",") ? template.split(",") : [template]
      );
      setDownloadableModal(false);
      setShowNotification({
        message: translate("downloadableFiles.modal.send.success"),
        type: "success",
        status: true,
      });
    } catch (e) {
      setDownloadableModal(false);
      setShowNotification({
        message: translate("downloadableFiles.modal.send.error"),
        type: "error",
        status: true,
      });
    }
  };

  return {
    translate,
    values,
    updateValues,
    fetchUpdatePrices,
    traits,
    DOMAIN,
    prices,
    isActiveRelatedEmail,
    setIsActiveRelatedEmail,
    saveData,
    isLoading,
    saveDataAndRecoverProject,
    downloadableModal,
    setDownloadableModal,
    downloadablePdfs,
    formatPriceWithoutCurrency,
    error,
    isSaved,
    closeModalCallback,
    isSeriousIllnesses,
    sendEmailDocument
  };
};

export default EditProjectsController;
