import { useContext, useEffect, useState } from "react";
import DownloadableFiles from "./DownloadableFiles";
import "./DownloadableFilesModal.scss";
import { I18nContext } from "../../../contexts/i18n.context";
import {
  ButtonDS,
  InputDS,
  ModalDS,
  SelectDS,
  TextareaDS,
} from "get-life-storybook-ts";
import { getValidateEmail } from "../../../utils/staticData";

interface DownloadableFilesModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  downloadablePdfs:
    | [
        {
          template: string;
          url: string;
        }
      ]
    | [];
  emailToSend?: string;
  sendEmail?: (
    email: string,
    text: string,
    template: string,
    leadId?: string
  ) => void;
  lang?: string;
  leadId?: string;
}

const DownloadableFilesModalDS = ({
  open,
  setOpen,
  downloadablePdfs,
  emailToSend,
  sendEmail,
  lang,
  leadId,
}: DownloadableFilesModalProps): JSX.Element => {
  const {
    state: { translate },
  } = useContext(I18nContext);

  const [email, setEmail] = useState(emailToSend);
  const [text, setText] = useState(
    process.env.REACT_APP_DOMAIN_LOCALE === "fr"
      ? translate("modal.sendPdf.placeholder.message", lang)
      : ""
  );
  const [documentSelected, setDocumentSelected] = useState("");
  const [loading, setLoading] = useState(false);

  let allDocumentsArray: string = "";
  const parseDocuments: { label: string; value: string }[] = [];
  downloadablePdfs?.map((key) => {
    let position = {
      label: translate(`document.download.${key.template}`),
      value: key.template,
    };
    parseDocuments.push(position);
    if (key.template === downloadablePdfs[0]?.template) {
      allDocumentsArray = allDocumentsArray.concat("", key.template);
    } else {
      allDocumentsArray = allDocumentsArray.concat(",", key.template);
    }
  });

  let allDocuments = {
    label: translate(`document.download.all`),
    value: allDocumentsArray,
  };

  parseDocuments.push(allDocuments);

  useEffect(() => {
    setLoading(false);
  }, [open]);

  useEffect(() => {
    if (!email) {
      setEmail(getValidateEmail(emailToSend));
    }
  }, [emailToSend]);

  const isSendEmailEnabled = sendEmail;
  const isSendButtonDisabled =
    !email || !text || !documentSelected || !getValidateEmail(email) || loading;

  return (
    <ModalDS
      open={open}
      onClose={() => setOpen(false)}
      icon="DownloadCloudIcon"
      title={translate("downloadableFiles.modal.title", lang)}
      content={
        <>
          <p
            className="BodyM font-normal text-[#424242] mb-[8px]"
            dangerouslySetInnerHTML={{
              __html: translate("downloadableFiles.modal.body", lang),
            }}
          />
          <DownloadableFiles downloadablePdfs={downloadablePdfs} lang={lang} />
          {isSendEmailEnabled ? (
            <div className="mt-[24px] flex flex-col gap-[16px]">
              <p className="BodyM font-normal text-[#424242]">
                {translate("downloadableFiles.modal.body.sendEmail", lang)}
              </p>
              <InputDS
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                icon="MailIcon"
                label={translate("modal.sendPdf.label.email", lang)}
                placeholder={translate("modal.sendPdf.placeholder.email", lang)}
              />
              <SelectDS
                placeholder="Selecciona un documento"
                value={documentSelected}
                onChange={(e) => setDocumentSelected(e.target.value)}
                options={parseDocuments}
              />
              <TextareaDS
                label={translate("modal.sendPdf.label.message", lang)}
                value={text}
                placeholder={translate(
                  "modal.sendPdf.placeholder.message",
                  lang
                )}
                onChange={(e) => setText(e.target.value)}
                style={{ height: 200 }}
              />
            </div>
          ) : null}
        </>
      }
      buttons={
        isSendEmailEnabled ? (
          <ButtonDS
            onClick={() => {
              setLoading(true);
              sendEmail!(
                email!,
                text,
                documentSelected,
                leadId ? leadId : undefined
              );
            }}
            label={translate("downloadableFiles.modal.body.sendButton", lang)}
            className="max-w-[320px] ml-auto"
            disabled={isSendButtonDisabled}
          />
        ) : undefined
      }
    />
  );
};

export default DownloadableFilesModalDS;
