import { ButtonDS, IconButton } from "get-life-storybook-ts";
import FAQ from "./FAQ";
import IconCards from "./IconCards";
import InsuranceSteps from "./InsuranceSteps";
import LandingBanner from "./LandingBanner";
import LandingContact from "./LandingContact";
import LandingController from "./Landing.controller";
import "./Landing.styles.scss";
import ReviewCards from "./ReviewCards";
import LandingCalculator from "./LandingCalculator";

const Landing = () => {
  const { broker, brokerId, calculatorData, translate, qsLang } =
    LandingController();

  const lang = (() => {
    if (broker?.brokerageId === 354) return "en";
    if (process.env.REACT_APP_ENVIROMENT && qsLang === "en") return "en";
    return undefined;
  })();

  const brokerPhone =
    broker?.brokerageId === 699 ? "910886967" : broker?.workPhone;

  return (
    <div className="LandingPage">
      <section className="LandingPage-header">
        <div className="LandingPage-header-logos">
          {broker && broker.logoFilename ? (
            <img
              src={broker.logoFilename}
              alt="logo del broker"
              className="LandingPage-header-brokerLogo"
            />
          ) : broker ? (
            <p className={"LandingPage-header-brokerName BodyM"}>
              {broker.brokerageName}
            </p>
          ) : null}
        </div>
        <div className="LandingPage-header-CTA">
          <ButtonDS
            href={`mailto:${broker?.email}`}
            label={translate("landing.button.sendEmail", lang)}
            rightIcon="MailForwardIcon"
            buttonType="tertiary"
            size="40"
          />
          <ButtonDS
            href={`tel:${broker?.workPhone}`}
            label={translate("landing.button.call", lang)}
            leftIcon="PhoneCallIcon"
            size="40"
          />
        </div>
        <div className="LandingPage-header-CTA-mobile">
          <IconButton
            href={`mailto:${broker?.email}`}
            icon="MailForwardIcon"
            buttonType="tertiary"
            size="40"
          />
          <IconButton
            href={`tel:${broker?.workPhone}`}
            icon="PhoneCallIcon"
            size="40"
          />
        </div>
      </section>
      <LandingCalculator
        brokerId={brokerId}
        data={calculatorData}
        translate={translate}
        lang={lang}
      />
      <InsuranceSteps translate={translate} lang={lang} />
      <IconCards
        phone={brokerPhone}
        email={broker?.email}
        translate={translate}
        lang={lang}
      />
      <LandingBanner translate={translate} lang={lang} />
      <ReviewCards translate={translate} lang={lang} />
      <FAQ lang={lang} />
      {brokerPhone ? (
        <LandingContact translate={translate} lang={lang} />
      ) : null}
      <footer className="LandingPage-footer">
        <div className="LandingPage-footer-wrapper max-w-content">
          <ButtonDS
            label={translate("landing.footer.conditionsText", lang)}
            ghost={true}
            buttonType="secondary"
            size="40"
            className="BodyM w-fit"
            href={translate("landing.footer.conditionsLink", lang)}
            target="_blank"
          />
          <ButtonDS
            label={translate("landing.footer.privacityText", lang)}
            ghost={true}
            buttonType="secondary"
            size="40"
            className="BodyM w-fit"
            href={translate("landing.footer.privacityLink", lang)}
            target="_blank"
          />
          <ButtonDS
            label={translate("landing.footer.cookiesText", lang)}
            ghost={true}
            buttonType="secondary"
            size="40"
            className="BodyM w-fit"
            href={translate("landing.footer.cookiesLink", lang)}
            target="_blank"
          />
        </div>
      </footer>
    </div>
  );
};

export default Landing;
