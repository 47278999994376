import { ButtonDS, GlobalIcon } from "get-life-storybook-ts";
import "./IconCards.scss";

const IconCards = ({
  phone,
  email,
  translate,
  lang,
}: {
  phone?: string;
  email?: string;
  translate: (key: string, lang?: string) => string;
  lang?: string;
}) => {
  if (!phone && !email) return null;
  return (
    <section className="IconCards" id="landing-contact">
      <div className="IconCards-wrapper max-w-content">
        <div className="IconCards-header">
          <h2
            className="IconCards-mainTitle H2"
            dangerouslySetInnerHTML={{
              __html: translate("landing.iconCards.title", lang),
            }}
          ></h2>
          <p className="IconCards-mainDescription H4">
            {translate("landing.iconCards.subtitle", lang)}
          </p>
        </div>

        <div className="IconCards-cards">
          {phone ? (
            <div className="IconCards-card">
              <span className="IconCards-icon">
                <GlobalIcon
                  iconName="CallIcon"
                  color={"currentColor"}
                  colorFill="transparent"
                  size="M"
                />
              </span>
              <h3 className="IconCards-title H4">
                {translate("landing.infoCards.phoneTitle", lang)}
              </h3>
              <p>
                {translate("landing.infoCards.phoneSubtitle", lang)}
                <br />
                <a href={`tel:${phone}`}>{phone}</a>
              </p>
              <ButtonDS
                href={`tel:${phone}`}
                rightIcon="ArrowRightIcon"
                size="40"
                label={translate("landing.infoCards.phoneButton", lang)}
              />
            </div>
          ) : null}
          {email ? (
            <div className="IconCards-card">
              <span className="IconCards-icon">
                <GlobalIcon
                  iconName="EmailIcon"
                  color={"currentColor"}
                  colorFill="transparent"
                  size="M"
                />
              </span>
              <h3 className="IconCards-title H4">
                {translate("landing.infoCards.emailTitle", lang)}
              </h3>
              <p>
                {translate("landing.infoCards.emailSubtitle", lang)}
                <br />
                <a href={`mailto:${email}`}>{email}</a>
              </p>
              <ButtonDS
                href={`mailto:${email}`}
                buttonType="tertiary"
                rightIcon="ArrowRightIcon"
                size="40"
                label={translate("landing.infoCards.emailButton", lang)}
              />
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default IconCards;
