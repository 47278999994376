import { ButtonDS, GlobalIcon, TooltipHover } from "get-life-storybook-ts";
import BrokerResponse from "../../../../api/request/Broker/Model/Response/BrokerResponse.model";
import { ListProjectsV3 } from "../../../../api/request/Lists/Model/Response/ListResponse.model";
import {
  DisableRecoverButton,
  DisableViewButton,
  DisableViewButtonAdmin,
  EditButton,
  enableEmailsSelectra,
  EnableOtpButton,
} from "../constants/logicButtons";

export const renderActions = (
  translate: (key: string) => string,
  project: ListProjectsV3,
  recoverLead: (leadId: string, project: any) => void,
  editLead: (project: any) => void,
  getOtp: (leadId: string) => void,
  viewLead: (leadId: string, project: any) => void,
  getDownloableFiles: (leadId: string) => void,
  broker?: BrokerResponse,
  DOMAIN?: string,
) => {
  const isAdmin = broker?.brokerageId === 1;
  const isSelectraEnable =
    broker?.brokerageId === 666 &&
    !enableEmailsSelectra.includes(broker?.email);
  const DisableRecover =
    !DisableRecoverButton.includes(project.status) ||
    ((project.hasMaxCapital || project.hasTelesubscription) &&
      !project.hasManualTarification) ||
    isSelectraEnable;
  const DisableRecoverByStatus = !DisableRecoverButton.includes(project.status);
  const DisableRecoverByMaxcapital =
    (project.hasMaxCapital || project.hasTelesubscription) &&
    !project.hasManualTarification;
  const DisableEdit =
    (project.hasTelesubscription && !project.hasMaxCapital) || isSelectraEnable;
  const DisableEditByTelesub =
    project.hasTelesubscription && !project.hasMaxCapital;
  const DisableView = isAdmin
    ? DisableViewButtonAdmin.includes(project.status)
    : DisableViewButton.includes(project.status);
  const DisableOtpButton =
    process.env.REACT_APP_DOMAIN_LOCALE === "sql_es" &&
    !EnableOtpButton.includes(project.status);

  const AllDisabled =
    DOMAIN === "sql_es" &&
    (project.market === "ES" || project.market === "AXA_WL_ES");

  const RecoverTooltip = DisableRecoverByMaxcapital
    ? translate("tables.tootlip.disableRecoverByMaxCapital")
    : DisableRecoverByStatus
      ? translate(`tables.tootlip.disableRecoverByStatus${project.status}`)
      : "";
  const EditTooltip = !EditButton.includes(project.status)
    ? translate("table.tooltip.editNotAvailable")
    : DisableEditByTelesub
      ? translate("tables.tootlip.editDisabled")
      : "";
  const ViewTooltip = EditButton.includes(project.status)
    ? translate("tables.tooltip.viewNotAvailable")
    : DisableView
      ? translate(`tables.tootlip.disableViewByStatus${project.status}`)
      : "";
  const OTPTooltip =
    DisableOtpButton || AllDisabled
      ? translate("tables.tooltip.viewNotAvailable")
      : "";

  const isRecoverDisabled = DisableRecover || AllDisabled;
  const isEditDisabled =
    !EditButton.includes(project.status) || DisableEdit || AllDisabled;
  const isViewDisabled = EditButton.includes(project.status) || DisableView;
  const isOTPDisabled = DisableOtpButton || AllDisabled;

  return (
    <div className="relative group">
      <ButtonDS
        label={translate("tables.actions")}
        rightIcon="ClickIcon"
        buttonType="secondary"
        size="32"
      />
      <div
        className="hidden group-hover:flex absolute top-[calc(100%_-_4px)] right-0 z-10 bg-white flex-col rounded-xl"
        style={{
          boxShadow:
            "0px -1px 0px 0px rgba(66, 66, 66, 0.10) inset, 0px 4px 8px 0px rgba(66, 66, 66, 0.10)",
        }}
      >
        <TooltipHover
          offsetY={-12}
          content={RecoverTooltip}
          direction="bottom left"
        >
          <div
            className="flex flex-row gap-[8px] py-[12px] px-[16px] rounded-t-xl hover:bg-[#f3f5f9]"
            onClick={() => {
              if (isRecoverDisabled) return;
              recoverLead(project.leadId, project);
            }}
            style={{ cursor: isRecoverDisabled ? "" : "pointer" }}
          >
            <GlobalIcon
              iconName="RecoverIcon"
              color={isRecoverDisabled ? "#A0A0A0" : "#555555"}
              size="XS"
            />
            <span
              className="flex-1 BodyM font-medium whitespace-nowrap"
              style={{
                color: isRecoverDisabled ? "#A0A0A0" : "#555555",
              }}
            >
              {translate("edit.project.button.recover")}
            </span>
          </div>
        </TooltipHover>
        <TooltipHover
          offsetY={-12}
          content={EditTooltip}
          direction="bottom left"
        >
          <div
            className="flex flex-row gap-[8px] py-[12px] px-[16px] hover:bg-[#f3f5f9]"
            onClick={() => {
              if (isEditDisabled) return;
              editLead(project);
            }}
            style={{
              cursor: isEditDisabled ? "" : "pointer",
            }}
          >
            <GlobalIcon
              iconName="PencilIcon"
              color={isEditDisabled ? "#A0A0A0" : "#555555"}
              className={isEditDisabled ? "" : "pointer"}
              size="XS"
            />
            <span
              className="flex-1 BodyM font-medium whitespace-nowrap"
              style={{
                color: isEditDisabled ? "#A0A0A0" : "#555555",
              }}
            >
              {translate("tables.actions.editProject")}
            </span>
          </div>
        </TooltipHover>
        <TooltipHover
          offsetY={-12}
          content={ViewTooltip}
          direction="bottom left"
        >
          <div
            className="flex flex-row gap-[8px] py-[12px] px-[16px] hover:bg-[#f3f5f9]"
            onClick={() => {
              if (isViewDisabled) return;
              viewLead(project.leadId, project);
            }}
            style={{
              cursor: isViewDisabled ? "" : "pointer",
            }}
          >
            <GlobalIcon
              iconName="EyeIcon"
              color={isViewDisabled ? "#A0A0A0" : "#555555"}
              className={isViewDisabled ? "" : "pointer"}
              size="XS"
            />
            <span
              className="flex-1 BodyM font-medium whitespace-nowrap"
              style={{
                color: isViewDisabled ? "#A0A0A0" : "#555555",
              }}
            >
              {translate("tables.actions.viewClient")}
            </span>
          </div>
        </TooltipHover>
        <TooltipHover
          offsetY={-12}
          content={OTPTooltip}
          direction="bottom left"
        >
          <div
            className="flex flex-row gap-[8px] py-[12px] px-[16px] rounded-b-[12px] hover:bg-[#f3f5f9]"
            onClick={() => {
              if (isOTPDisabled) return;
              getOtp(project.leadId);
            }}
            style={{
              cursor: isOTPDisabled ? "" : "pointer",
            }}
          >
            <GlobalIcon
              iconName="LinkToIcon"
              color={isOTPDisabled ? "#A0A0A0" : "#555555"}
              className={isOTPDisabled ? "" : "pointer"}
              size="XS"
            />
            <span
              className="flex-1 BodyM font-medium whitespace-nowrap"
              style={{
                color: isOTPDisabled ? "#A0A0A0" : "#555555",
              }}
            >
              {translate("tables.actions.otpLink")}
            </span>
          </div>
        </TooltipHover>
        <div
          className="flex flex-row gap-[8px] py-[12px] px-[16px] hover:bg-[#f3f5f9]"
          onClick={() => getDownloableFiles(project.leadId)
          }
          style={{
            cursor: "pointer",
          }}
        >
          <GlobalIcon
            iconName="FileDownload"
            color={"#555555"}
            className={"pointer"}
            size="XS"
          />
          <span
            className="flex-1 BodyM font-medium whitespace-nowrap"
            style={{
              color: "#555555",
            }}
          >
            {translate("tables.actions.downloadables")}
          </span>
        </div>
      </div>
    </div>
  );
};
