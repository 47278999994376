interface stageI {
    title: string;
    value: string;
}

export const Stages = (translate: (key: string) => string) : stageI[] =>[
    {
        title: translate("projects.stage.pendingIssued"),
        value: "product_questions_pending,questions_pending,prestack_pending,personaldata_pending,telesubscription,upsell_pending,nationality_pending,address_pending,beneficiaries_pending,beneficiariestypes_pending,physicalperson_pending,bankingentity_pending,legalperson_pending,paymentmethodpending_pending,payment_pending,payment_validation_pending,idcard_pending,legalnote_pending,signature_pending"
    },
    {
        title: translate("projects.stage.issued"),
        value: "docs_pending,ops_review,it_review,insurer_review,csv_pending,complete"
    },
    {
        title: translate("projects.stage.rejectedCanceled"),
        value: "canceled,rejected,claim"
    }
];
  
