/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import WhiteButton from "../../components/buttons/WhiteButton";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import { ButtonDS } from "get-life-storybook-ts";
import Question from "../../components/question/Question";
import ModalPopUp from "./ModalPopUp";
import apiLeadQuestionGet from "../../../api/request/apiLeadQuestionGet";
import apiLeadQuestionCarrierGet from "../../../api/request/apiLeadQuestionCarrierGet";
import QuestionAlert from "../../components/question/QuestionAlert";

import "./css/questions.scss";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { GUARANTEE, selectModal } from "./../../../utils/staticData";
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import { GoogleContext } from "../../../contexts/GoogleTagManager.context";
import { I18nContext } from "../../../contexts/i18n.context";
import HttpLeadRepository from "../../../api/request/Lead/lead.service";
import { getTrackingUtms } from "../../../api/request/apiLeadTrackingGet";
import Spinner from "get-life-storybook-ts/lib/components/Icons/Spinner";
import RenderQuestion from "../../components/question/RenderQuestion";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Questions() {
  const { brokerId, carrierId, leadId, token, broker } = useContext(ContextGetlife);

  const {
    state: { translate },
  } = React.useContext(I18nContext);

  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;
  const leadRepository = new HttpLeadRepository(token);

  const [lastSaved, setLastSaved] = useState(1);

  const navigate = useNavigate();
  const goToCheckPoint = () => navigate(`/checkpoint`);

  const [downgradedOpen, setDowngradedOpen] = useState(false);
  const handleDowngradedClose = () => {
    setDowngradedOpen(false);
  };
  const [continueDisabled, setContinueDisabled] = useState(true);
  const [continueHidden, setContinueHidden] = useState(true);
  const [code, setCode] = useState(100);
  const [downgraded, setDowngraded] = useState(false);
  const [actualQuestion, setActualQuestion] = useState(false);
  const [answeredQuestions, setAnsweredQuestions] = useState(false);
  const [previousIllnessesAnswered, setPreviousIllnessesAnswered] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [idEdited, setIdEdited] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [popupsName, setPopupsName] = useState("");
  const [modeModal, setModeModal] = useState("1");
  const [infoModal, setInfoModal] = useState({});
  const [lastQuestion, setLastQuestion] = useState({
    questionId: '',
    answer: ''
  })
  const [hasTelesubcription, setHasTelesubscription] = useState(false);

  const { handleTrackerQuestion } = useContext(GoogleContext);

  const handleActualQuestion = (e) => {
    setActualQuestion(e);

    if (e.questionId) {
      leadRepository.getPostStackData(leadId).then((leadResponse) => {
        getTrackingUtms(leadId).then(utms => {

          const eventData = {
            intent: "loan",
            leadId: leadId,
            phone: leadResponse.lead.phone,
            email: leadResponse.lead.email,
            birthDate: leadResponse.lead.birthdate,
            calculatedCapital: parseInt(leadResponse.lead.capital),
            gender: leadResponse.lead.gender,
            calculatedPremium: parseFloat(leadResponse.price),
            postalCode: leadResponse.lead.zipcode,
            coverage: GUARANTEE[leadResponse.lead.insuranceType],
            brokerId: brokerId,
            entryPage: utms.entryPage,
            referrerUrl: utms.referrerUrl
          }

          handleTrackerQuestion({
            type: "FS",
            event: "Question Answered",
            data: {
              questionId: e.questionId,
              stack: "Questions",
              page: window.location.href,
              platform: "Broker",
              ...eventData
            }
          });
          handleTrackerQuestion({
            type: "GA",
            data: {
              event: "virtualPageview",
              questionId: e.questionId,
              stack: "Questions",
              page: window.location.href,
              platform: "Broker",
              pageTitle: "Life5 Broker - QuestionViewed",
              ...eventData
            },
          });
        })
      })
    }
  }

  const handleAnsweredQuestions = (e) => setAnsweredQuestions(e);

  const handleIdEdited = (e) => setIdEdited(e);

  const refreshQuestion = async () => {
    try {
      if (actualQuestion) {
        throw new Error();
      }
      const data =
        typeof carrierId === "undefined"
          ? await apiLeadQuestionGet(leadId, brokerId, token)
          : await apiLeadQuestionCarrierGet(leadId, brokerId, carrierId, token);

      setHasTelesubscription(data.telesubscription);

      setCode(data.code);
      setContinueDisabled(data.code !== 0);
      setContinueHidden(data.code !== 0);
      handleAnsweredQuestions(data.answeredQuestions);
      // if (answeredQuestions === false) {
      //   handleAnsweredQuestions(data.answeredQuestions);
      // } else {
      //     answeredQuestions.unshift(data.answeredQuestions[0]);
      //     handleAnsweredQuestions([...answeredQuestions]);
      // }
      handleActualQuestion(data.question);
      setDowngraded(data.downgraded);

      if (data.question !== null) {
        if (
          data.question.popups !== undefined &&
          data.question.popups.length > 0
        ) {
          setModalInfo(selectModal(data.question.popups[0]));
          setPopupsName(data.question.popups[0]);
        }
      }

      if (data.code === -1) {
        const event = "leadRejected";
        const eventData = {
          platform: "Broker",
          questionId: lastQuestion.questionId,
          rejectReason: lastQuestion.answer,
          brokerEmployeeId: brokerId,
          brokerId: broker.brokerageId,
        }

        handleTrackerQuestion({
          type: "FS",
          event: event,
          data: {
            ...eventData
          }
        });
        handleTrackerQuestion({
          type: "GA",
          data: {
            event: event,
            ...eventData
          },
        });
      }
    } catch (e) { }
  };

  const handleNextQuestion = async (questionId, answer) => {
    handleActualQuestion(false);

    handleAnsweredQuestions(false);

    setLastSaved(lastSaved + 1);
    setLastQuestion({ questionId, answer })
  };

  const callPhone = () => {
    window.open(`tel:${process.env.REACT_APP_OSCAR_TEL}`);
  };

  const prepareSecondFunctionModal = () => {
    if (popupsName === "SavingGrafics") {
      const url = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/lead/view/savings/${leadId}`;
      document.getElementById("downloadButton").setAttribute("href", url);
      document.getElementById("downloadButton").click();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshQuestion();
  }, []);

  useEffect(() => {
    const comproveModal = () => {
      // campo back !== null
      popupsName !== "" ? setShowModal(true) : setShowModal(false);
    };

    comproveModal();
  }, [popupsName]);

  useEffect(() => {
    if (lastSaved !== 1) {
      refreshQuestion();
    }
  }, [lastSaved]);

  useEffect(() => {
    if (downgraded === 1 || downgraded === "1") {
      setDowngradedOpen(true);
    }
  }, [downgraded]);
  return (
    <>
      <Dialog
        fullWidth={false}
        maxWidth={"lg"}
        open={downgradedOpen}
        onClose={handleDowngradedClose}
        TransitionComponent={Transition}
      >
        <DialogTitle
          disableTypography
          className={"questions-downgraded-dialog-close"}
        >
          <IconButton onClick={handleDowngradedClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={"questions-downgraded-dialog-content"}>
          <p>
            Debido a los datos que nos has facilitado, no podemos ofrecerte una
            garantía de <br />
            <span>Invalidez Permanente Absoluta</span>.
          </p>
          <p>
            A partir de este punto te vamos a preguntar lo necesario para darte
            un precio para la garantía de
            <br /> <span>Fallecimiento</span>
          </p>
        </DialogContent>
      </Dialog>

      <div className="insuranceApplication-mainContainer">
        <div className="insuranceApplication-container">
          <header className="insuranceApplication-headContainer">
            <h1 className="insuranceApplication-h1">{translate("questions.header.title")}</h1>
            <p>
              {translate("questions.header.subtitle")}
            </p>
          </header>

          {(DOMAIN === "es" || DOMAIN === "pt") && (
            <nav className="insuranceApplication-buttonContainer">
              <ButtonPrimary content="Llamar" onClick={callPhone} />

              <ButtonPrimary content="Reservar cita" />
              <WhiteButton content="Notif. Incidencia" />
            </nav>)}
          <main>
            <div className="insuranceApllication-mainHeader">
              <p>{translate("questions.label.title")}</p>
            </div>
            {actualQuestion === false && (
              <div className="flex justify-center">
                <Spinner />
              </div>

            )}
            {actualQuestion !== false && actualQuestion !== null && (
              <Question
                content={actualQuestion.content}
                type={actualQuestion.type}
                placeholder={translate("questions.placeholder.select")}
                default={""}
                answer={actualQuestion.value}
                answers={actualQuestion.answers}
                handleNextQuestion={handleNextQuestion}
                isEditing={isEditing}
                answered={false}
                token={token}
                brokerId={brokerId}
                broker={broker}
                leadId={leadId}
                questionId={actualQuestion.questionId}
                componentName={actualQuestion?.componentName}
                previousIllnessesAnswered={previousIllnessesAnswered}
                question={actualQuestion}
              />
            )}
            {actualQuestion === null && code !== 0 && <QuestionAlert telesubscription={hasTelesubcription} />}
            {answeredQuestions !== false &&
              (
                <RenderQuestion
                  questions={answeredQuestions}
                  handleActualQuestion={handleActualQuestion}
                  handleIdEdited={handleIdEdited}
                  handleNextQuestion={handleNextQuestion}
                  isEditing={isEditing}
                />
              )
            }
          </main>
        </div>
        <footer className="insuranceApplication-footerMainContainer">
          <div
            className="insuranceApplication-footerContainer"
            style={{ display: continueHidden ? "none" : "flex" }}
          >
            <ButtonDS
              disabled={continueDisabled}
              onClick={() => goToCheckPoint()}
              label={translate("postStack.buttons.continue")}
              buttonType={"primary"}
            />
          </div>
        </footer>
      </div>

      <ModalPopUp
        handleModal={() => setShowModal(false)}
        open={showModal}
        title={modalInfo.title}
        content={modalInfo.body}
        subText={modalInfo.subText}
        modeModal={modeModal}
        popupsName={popupsName}
        infoModal={infoModal}
        setModeModal={(e) => setModeModal(e)}
        buttonPrimaryContent={modalInfo.firstButton}
        buttonPrimarySubmit={() => setShowModal(false)}
        buttonSecondary
        buttonSecondaryContent={
          modalInfo.SecondaryButton ? modalInfo.SecondaryButton : null
        }
        buttonSecondarySubmit={prepareSecondFunctionModal}
      />
      <a target="_blank" id="downloadButton" style={{ display: "none" }}></a>
    </>
  );
}
